import moment from "moment";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { MerchantImageBaseUrl } from "../../../../app/Apis/merchantDashboardApis/Constent";
// import { downloadSurveyData } from "../../../../Services/SurveyAPIService";
import BackButton from "../../../Dashboard/Components/Common/BackButton/BackButton";
import Loader from "../../../Loader/Loader";
import { downloadContent } from "../../Create/PollUtils";
import ChannelAnalytics from "../View/ChannelAnalytics/ChannelAnalytics";
import PollInfo from "../View/PollInfo/PollInfo";
import Chart from "./Chart";
import "./style.css";

const ViewPollChart = (props) => {
  console.log(`----------- View Survey Chart -----------`);

  const loading = useSelector((state) => state.survey?.loading);
  const { state } = useLocation();
  const { surveyData, type, myPage, numbeOfRow, chartData } = state;
  const [questionsArray, setQuestionsArray] = useState([]);

  useEffect(() => {
    let tmpArr = [];
    if (chartData?.length) {
      for (let storeItem of chartData) {
        if (!tmpArr?.length) {
          tmpArr.push({
            questionId: storeItem?.questionId,
            questionTitle: storeItem?.question,
            questionTypeId: storeItem?.questionTypeId,
            questionType: storeItem?.questionType,
            answersArr: [
              {
                option: storeItem?.answer,
                value: storeItem?.optionResponse,
              },
            ],
            totalResponse: storeItem?.totalResponse,
          });
          continue;
        }
        let obj = tmpArr.find(
          (item) => item?.questionId === storeItem?.questionId
        );
        if (obj !== undefined && Object.keys(tmpArr).length) {
          for (let arrItem of tmpArr) {
            if (arrItem?.questionId === storeItem?.questionId) {
              // arrItem.totalResponse =
              //   arrItem?.totalResponse + storeItem?.totalResponse;
              arrItem.answersArr.push({
                option: storeItem?.answer,
                value: storeItem?.optionResponse,
              });
            }
          }
        } else {
          tmpArr.push({
            questionId: storeItem?.questionId,
            questionTitle: storeItem?.question,
            questionTypeId: storeItem?.questionTypeId,
            questionType: storeItem?.questionType,
            answersArr: [
              {
                option: storeItem?.answer,
                value: storeItem?.optionResponse,
              },
            ],
            totalResponse: storeItem?.totalResponse,
          });
        }
      }
    }
    setQuestionsArray(tmpArr);
  }, []);

  const downloadSurveyReport = async () => {
    try {
      let surveyId = surveyData?.id;
      const qParams = {
        id: surveyId,
      };
      // const response = await downloadSurveyData(
      //   "get",
      //   "/Survey/DownloadExecutedSurvey",
      //   qParams
      // );
      // downloadContent(response.data);
    } catch (e) {
      console.log(`Error in downloadSurveyReport:`, e);
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="container notification_wrapper viewSurvey">
        {/* ================================ Title ================================ */}
        <div className="title-area d-flex">
          <div className="left-icon">
            <BackButton
              pathname={"/survey/data"}
              paginationNumber={myPage}
              numbeOfRow={numbeOfRow}
            />
          </div>

          <div className="gbl-heading-txt title-view-survey">
            <div className="title-area">
              <h2>View Survey</h2>
            </div>
          </div>
          <div className="">
            <button
              className="export-button fw-normal float-end bg-black text-light ms-4"
              onClick={downloadSurveyReport}
            >
              Download Data
            </button>
          </div>
        </div>

        {/* ================================ POLL Data ================================ */}
        <PollInfo surveyData={surveyData} />

        {/* ================================ Update Details ================================ */}
        <ChannelAnalytics surveyData={surveyData} />

        {/* ================================ POLL Charts ================================ */}
        <h2 className="leftheader">Poll Results</h2>
        {questionsArray?.length === 0 && (
          <>
            <h4 className="text-center my-5">No poll response found.</h4>
            <br />
            <br />
          </>
        )}

        {questionsArray?.length > 0 &&
          questionsArray?.map((item, index) => (
            <>
              <Chart
                questionNo={index + 1}
                graphData={item}
                surveyData={surveyData}
              />
            </>
          ))}

        {questionsArray?.length > 0 && (
          <div className="best-result-img">
            <img src={`${MerchantImageBaseUrl}/info-icon.svg`} />
            Please export the data to see all of the short answers.
          </div>
        )}
      </div>
    </>
  );
};
export default ViewPollChart;
