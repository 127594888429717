import React, { useState } from 'react';

const ViewPollData = (props) => {
    const { reviewOptions = [], questionData, targetUserCount } = props;
    return (
        <div className="pollOuter">
            {/* <div className="d-flex mb-3">
                <div className="totalTarget"><label>Total Target: </label> <span>{reviewOptions.targetUserCount}</span></div>
                <div className="totalResponse mx-5"><label>Total Response: </label> <span>{reviewOptions.totalResponseCount}</span></div>
            </div>
            <div className="d-flex mb-3">
            <div className="totalTarget"><label>Guest User Response: </label> <span>{reviewOptions.totalGuestUserResponse}</span></div>
            </div> */}
            <div className="pollList">
                {reviewOptions.length &&
                    reviewOptions.map((items, index) => {
                        return (
                            <div key={index} className="pollRow d-flex justify-content-between">
                                <div className="split">
                                    <label htmlFor={items.questionOptionValue}>
                                        {items.questionOptionValue}
                                    </label>
                                </div>
                                <span>{items.optionResponseCount && items.totalResponseCount > 0 ? ((items.optionResponseCount/items.totalResponseCount) * 100).toFixed(1) : 0}%</span>
                                <span className={(items.optionResponseCount/items.totalResponseCount) == 100 ? `progressBar full` : `progressBar`} style={{ width: `${((items.optionResponseCount/items.totalResponseCount) * 100).toFixed(1)}%` }}></span>
                                <span className="optionResponseCount">{items.optionResponseCount > 0 ? items.optionResponseCount: ''}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ViewPollData;
