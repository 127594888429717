import React, { useState, useEffect } from "react";
import PieCharts from "../../../Dashboard/Components/Common/Chart/PieCharts";
import { pollAnalysisData } from "../../../../app/Redux/Actions/PollActions/PollActions";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "../../../Dashboard/Components/Common/AutoComplete/AutoComplete";

const ViewPollAnalysis = (props) => {
  const { pollId, pollQuestions, pollData } = props;
  let tempQues = [...pollQuestions]
  const [genderData, setGenderData] = useState([]);
  const [filterBtnDisable, setFilterBtnDisable] = useState(true);
  const genderAnalysisData = useSelector(
    (state) => state.poll?.analysisData?.data
  );

  const [showGender, setShowGender] = useState(0);
  const [question, setQuestion] = useState("");
  const [optionId, setOptionId] = useState("");
  const [filteredOption, setFilteredOption] = useState([]);

  const genderColors = ["#da5dd3", "#308be2", "#949090"];
  const dispatch = useDispatch();
  const getAnalysisData = () => {
    dispatch(
      pollAnalysisData(
        pollId,
        question?.questionId || null,
        optionId?.questionOptionId || null
      )
    );
  };
  useEffect(() => {
    getAnalysisData();
  }, [pollId]);

  useEffect(() => {
    let genderValue = 0;
    let genderArr;
    let sortedGender = genderAnalysisData?.["gender"]?.sort((a, b) =>
      a["title"] > b["title"] ? 1 : -1
    );
    genderArr = sortedGender?.map(
      (v, key) => (
        (genderValue += v.value),
        {
          ...v,
          color: genderColors[key],
        }
      )
    );

    setGenderData(genderArr);
    if(genderArr){
      setFilterBtnDisable(false);
    }
    setShowGender(genderValue);
  }, [genderAnalysisData]);

  const handleChangeQuestion = (e) => {
    setQuestion(e || null);
    setOptionId(null);
    if (e?.questionId) {
      getQuestionOption(e?.questionId);
    }
  };

  const getQuestionOption = (selectedQuestion) => {
    const filteredOption = tempQues?.filter(
      (option) => option?.questionId === selectedQuestion
    );
    setFilteredOption(filteredOption?.[0]?.questionOptions || null);
  };
  return (
    <>
      <div className="container analysisWrapper">
        <div className="d-flex analysisTopRow">
              <div className="totalTarget"><label>Guest Response: </label> <span>{pollData?.totalGuestUserResponse}</span></div>
              <div className="totalTarget mx-5"><label>Target Response: </label> <span>{pollData?.totalTargetUserResponse}</span></div>
              <div className="totalResponse mx-5"><label>Total Response: </label> <span>{pollData?.totalUserResponse}</span></div>
            </div>
        <div className="row">
          <div className="col-sm-3 invisible_border autocomplete_dropdown">
            <AutoComplete
              data={tempQues || []}
              keyText="questionTitle"
              label="Question"
              value={question || null}
              onChange={(e, value) => {
                if(value){
                  handleChangeQuestion(value, "locationId");
                }
              }}
              onOpen={() => {
                document.body.classList.add("MuiDropdown-Opened");
              }}
              onClose={() => {
                document.body.classList.remove("MuiDropdown-Opened");
              }}
            />
          </div>
          <div className="col-sm-3 invisible_border autocomplete_dropdown">
            <AutoComplete
              data={filteredOption || []}
              keyText="questionOptionValue"
              label="Option"
              value={optionId}
              onChange={(e, value) => {
                setOptionId(value || null);
              }}
              onOpen={() => {
                document.body.classList.add("MuiDropdown-Opened");
              }}
              onClose={() => {
                document.body.classList.remove("MuiDropdown-Opened");
              }}
            />
          </div>
          <div className="col-sm-3 invisible_border">
            <button
              className="btn btn-primary rounded-pill btn-filter"
              type="submit"
              onClick={() => getAnalysisData()}
              disabled={filterBtnDisable || (question?.length == 0)}
            >
              View Data
            </button>
          </div>
        </div>
        <div className="analysisPieChart">
          <div className="row mb-4">
            <div className="col-sm-6">
              <div className="chart-box-view border-0">
                {showGender !== 0 ? (
                  <PieCharts chartData={genderData} />
                ) : (
                  <p className="pie-chart-no-data">No data</p>
                )}
                <div className="color-dotted">
                  <ul className="list-unstyled mb-0 mt-4">
                    <li>
                      <span className="male-dot"> </span> Male{" "}
                    </li>
                    <li>
                      <span className="female-dot"> </span> Female
                    </li>
                    <li>
                      <span className="other-dot"> </span> Others
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewPollAnalysis;
