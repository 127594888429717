import React, { useState } from "react";
import "./style.css";

const ViewMore = (props) => {
  const [moreContent, setMoreContent] = useState(true);

  const toggleView = () => {
    moreContent ? setMoreContent(false) : setMoreContent(true);
  };

  return (
    <>
      {props?.content?.length < 200 && (
        <>
          {props?.sNo + 1}.&nbsp;
          {props?.content}
        </>
      )}
      {props?.content?.length > 200 && moreContent && (
        <>
          {props?.sNo + 1}.&nbsp;
          {props?.content?.slice(0, 200) + "..."}&nbsp;&nbsp;
          <a className="viewmore_text" href onClick={() => toggleView()}>
            View more
          </a>
        </>
      )}

      {props?.content?.length > 200 && !moreContent && (
        <>
          {props?.sNo + 1}.&nbsp;
          {props?.content}&nbsp;&nbsp;
          <a className="viewmore_text" href onClick={() => toggleView()}>
            View less
          </a>
        </>
      )}
    </>
  );
};

export default ViewMore;
