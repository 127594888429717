import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const PieChartOfficial = ({ chartData }) => {

  const incomeColors = [
    "#a317a7",
    "#ffbb39",
    "#19dacc",
    "#289ebc",
    "#0183ff",
    "#4f4e78",
  ];

  const [chartOptions] = useState({
    chart: {
      type: "pie",
    },

    title: null,

    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },

    plotOptions: {
      pie: {
        cursor: "pointer",
        innerSize: "75%",
        size: "60%",
        showInLegend: true,
      },
    },

    series: [
      {
        name: "Survey",
        colorByPoint: true,
        data: chartData.answersArr.map((element, index) => {
          return {
            y: (element?.value / chartData?.totalResponse) * 100,
            name: element?.option,
            color: incomeColors[index],
            dataLabels: {
              color: incomeColors[index],
            },
          };
        }),
      },
    ],
  });

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </>
  );
};

export default PieChartOfficial;
