import axios from "axios";
import {
  BaseUrl,
  UserWebppBaseUrl,
  BaseUrlPro,
} from "./merchantDashboardApis/Constent";
import { decryptItemInLocal, getApiPath } from "../../utils/utils";

const getToken = () => {
  const data = decryptItemInLocal("AuthToken");
  const authdata = data ? data?.data : null;
  return authdata?.authTokenValue;
};
const ApiSubscriptionKey =
  window.globalProps?.envs?.REACT_APP_APIM_SUBSCRIPTION_KEY ||
  process.env.REACT_APP_MERCHANT_API_SUBSCRIPTION_KEY;

const headers = () => {
  const token = getToken();
  if (token) {
    return {
      Authorization: `Bearer ${getToken()}`,
      "Ocp-Apim-Subscription-Key": ApiSubscriptionKey,
    };
  }
  return {};
};

export const ApiCall = (
  method,
  url,
  data = {},
  responseType,
  loader = false,
  v2 = false
) => {
  switch (String(method).toLowerCase()) {
    case "app":
      return axios.get(`${UserWebppBaseUrl}v1${url}`, { headers: headers() });
    case "get": {
      let filteredData = data
        ? Object.keys(data)
            .filter((k) => {
              return data[k] !== "";
            })
            .reduce((cur, key) => {
              return Object.assign(cur, { [key]: data[key] });
            }, {})
        : false;
      let config = {
        method: "get",
        url: `${v2 ? BaseUrlPro : BaseUrl}${url}`,
        params: filteredData,
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Ocp-Apim-Subscription-Key": ApiSubscriptionKey,
        },
        responseType: responseType,
      };
      return axios(config).then().catch();
      // return axios.get(`${v2 ? BaseUrlPro : BaseUrl}${url}`,{params: filteredData && filteredData}, { headers: headers() });
    }
    case "post":
      return axios.post(`${v2 ? BaseUrlPro : BaseUrl}${url}`, data, {
        headers: headers(),
      });
    case "put":
      return axios.put(`${v2 ? BaseUrlPro : BaseUrl}${url}`, data, {
        headers: headers(),
      });
    case "delete":
      return axios.delete(`${v2 ? BaseUrlPro : BaseUrl}${url}`, {
        headers: headers(),
      });
    default:
      break;
  }
};

export const GetAllSurveyData = async (
  search = "",
  CurrentPage = "",
  PageSize = "",
  from_date = "",
  to_date = "",
  MerchantId = "",
  scheduleDate = "",
  channel = "",
  status,
  sorting = false
) => {
  const params = {
    SearchString: !!search ? search : null,
    CurrentPage: !!CurrentPage ? CurrentPage : null,
    PageSize: !!PageSize ? PageSize : null,
    ScheduledDate: !!scheduleDate ? scheduleDate : null,
    Channel: !!channel ? channel : null,
    Status: !!status ? status : null,
  };
  const surveyAPI = getApiPath("get", "Survey/Surveys", "", params, "");
  const allSurvey = await axios(surveyAPI);
  return allSurvey;
};

export const postData = async (formData) => {
  let config = {
    method: "post",
    url: `${BaseUrl}/Survey`,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": ApiSubscriptionKey,
    },
    data: formData,
  };
  return axios(config).then().catch();
};

export const deleteDraftSurvey = async (id) => {
  let config = {
    method: "delete",
    url: `${BaseUrl}/Survey/DeleteSurveyDraft/${id}`,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": ApiSubscriptionKey,
    },
  };
  return axios(config).then().catch();
};

export const getById = async (id) => {
  let config = {
    method: "get",
    url: `${BaseUrl}/Survey/${id}`,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": ApiSubscriptionKey,
    },
  };
  return axios(config).then().catch();
};

export const editSurvey = async (id) => {
  let config = {
    method: "get",
    url: `${BaseUrl}/Survey/${id}`,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": ApiSubscriptionKey,
    },
  };
  return axios(config).then().catch();
};

export const cancelAPi = (method, url, qParams = {}) => {
  console.log("req body=======", method, url, qParams);
  return ApiCall(method, url, qParams);
};

export const getSurveyDataDraft = () => {
  let config = {
    method: "get",
    url: `${BaseUrl}/Survey/Drafts?IsDraft=1&CurrentPage=1&PageSize=10`,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Ocp-Apim-Subscription-Key": ApiSubscriptionKey,
    },
  };

  return axios(config).then().catch();
};

export const getSurveyDashboardData = () => {
  let config = {
    method: "get",
    url: `${BaseUrl}/Survey/SurveyDashboard`,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Ocp-Apim-Subscription-Key": ApiSubscriptionKey,
    },
  };

  return axios(config).then().catch();
};

export const getSurveyDownload = (url, Token) => {
  return axios
    .get(BaseUrl + `/${url}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Ocp-Apim-Subscription-Key": ApiSubscriptionKey,
      },
      responseType: "blob",
    })
    .then((res) => {
      return res;
    })
    .catch((error) => error);
};

export const getSurveyListData = async () => {
  const getDashBoardAPIPath = getApiPath("get", "Survey/Surveys", "", "", "");
  return await axios(getDashBoardAPIPath);
};

export const getSurveyGraphData = (method, url, qParams = {}) => {
  return ApiCall(method, url, qParams);
};
