import React, { useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const BarChartOfficial = ({ chartData, surveyData }) => {

  const range = surveyData?.questions?.find(
    (element) => element?.questionId === chartData?.questionId
  )?.questionOptions;

  let minRange = JSON.parse(range[0]?.questionOptionValue)?.key;
  minRange = +minRange;

  let maxRange = JSON.parse(range[1]?.questionOptionValue)?.key;
  maxRange = +maxRange;

  let categoriesArr = [];
  let tmpObjArr = [];

  if (minRange < maxRange) {
    for (let i = minRange; i <= maxRange; i++) {
      categoriesArr.push(i);
      tmpObjArr.push({
        key: i,
        value: 0,
      });
    }
  }

  for (let element of tmpObjArr) {
    let answerValue = chartData?.answersArr?.find(
      (item) => item.option === "" + element.key
    )?.value;
    element.value = answerValue;
  }

  const [chartOptions] = useState({
    chart: {
      type: "column",
    },

    title: null,

    xAxis: {
      categories: categoriesArr,
      tickWidth: 0,
    },

    yAxis: {
      allowDecimals: false,
      gridLineWidth: 1,
      title: null,
      lineWidth: 1,
    },

    series: [
      {
        name: "Survey",
        colorByPoint: false,
        pointWidth: 90,
        color: "#3cae8f",
        dataLabels: {
          enabled: true,
          y: 30,
          color: "#ffffff",
        },
        data: tmpObjArr.map((element) => {
          return {
            y: element?.value,
            color: "#3cae8f",
            dataLabels: {
              color: element?.value > 0 ? "#ffffff" : "#000000",
            },
          };
        }),
      },
    ],
  });

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </>
  );
};

export default BarChartOfficial;
