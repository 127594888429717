import React from "react";
import "./NotFound.css";


const PageNotFound = () => {
    return (
        <div id="notFoundContainer">
            <div><img src="/404.gif" className="style404ui" /></div>
            <h2>Looks like you are lost!</h2>
            <p>The page you are looking for doesn’t exist.</p>
            <button id="roundButton404">
               <a href="/">Go to Homepage</a>
            </button>
        </div>
    )
}
export default PageNotFound;