import React, { useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { values } from "lodash";

const ColumnChartOfficial = ({ chartData, surveyData }) => {

  const incomeColors = [
    "#3cae8f",
    "#a317a7",
    "#ffbb39",
    "#19dacc",
    "#289ebc",
    "#0183ff",
    "#4f4e78",
  ];

  let question = surveyData?.questions?.find(
    (element) => element?.questionId === chartData?.questionId
  );
  let categoriesArr = question?.questionOptions?.map((element) => {
    return element?.questionOptionValue;
  });
  let optionsArr = categoriesArr.map((element) => {
    let obj = chartData?.answersArr.find((item) => element === item?.option);
    if (obj !== undefined) {
      return obj?.value;
    } else {
      return 0;
    }
  });

  const [chartOptions] = useState({
    chart: {
      type: "column",
    },

    title: null,

    xAxis: {
      categories: categoriesArr,
      tickWidth: 0,
    },

    yAxis: {
      allowDecimals: false,
      gridLineWidth: 1,
      title: null,
      lineWidth: 1,
    },

    series: [
      {
        name: "Survey",
        colorByPoint: false,
        pointWidth: 30,
        color: "#3cae8f",
        data: optionsArr.map((element, index) => {
          return {
            y: element,
            color: incomeColors[index],
          };
        }),
      },
    ],
  });

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </>
  );
};

export default ColumnChartOfficial;
