import React from "react";
import { Modal } from "react-bootstrap";
import CloseImage from "../../../assets/css/image/cross-icon.svg";
import "./styles.css";
import TableBodyComp from "../../Dashboard/Components/Common/TableBodyComp";
import TableHead from "../../Dashboard/Components/Common/TableHead";
const CampDetailsReport = (props) => {
  const headerFields = [
    { id: "channelName", label: "Campaign Name" },
    { id: "campaignLocation", label: "Campaign Location" },

    { id: "campaignDuration", label: "Campaign Duration" },
  ];
  // const formattedString = props?.data?.campaignName?.split(",");

  return (
    <>
      {props?.data && (
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="draftmerchant_modal_pop"
        >
          <Modal.Header>
            <div className="close_sort">
              <div>
                <img
                  src={CloseImage}
                  className="close_icon"
                  alt="close"
                  onClick={props.onHide}
                />
              </div>
              <Modal.Title
                id="contained-modal-title-vcenter"
                style={{ marginLeft: "7px" }}
              >
                {props.title}
              </Modal.Title>
            </div>
          </Modal.Header>
          <div
            className="draft-row-line_pop"
            style={{ marginTop: "10px" }}
          ></div>
          <Modal.Body id="modal">
            <>
              <div className="table_group">
                <table className="table border table-hover">
                  <TableHead fields={headerFields} />
                  <TableBodyComp
                    data={props?.data}
                    //page={page}
                    //rows={rowsPerPage}
                    cols={headerFields?.length}
                  />
                </table>
              </div>
              {/* <div className="row p-4">
                <div className="col-10">
                  <ol>
                    {props?.data?.campaignName?.length > 0 &&
                      formattedString?.map((item) => {
                        return <li className="draft_head"> {item}</li>;
                      })}
                  </ol>
                </div>
              </div> */}
            </>

            {props?.data.length === 0 ||
              (props?.data?.campaignName?.length === 0 && (
                <div className="nodata_head">No records found.</div>
              ))}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default CampDetailsReport;
