import React from "react";
import "./ProgressBar.css";
const ProgressBar = ({ width }) => {
   const progressStyle = {
    backgroundColor: "#000",
    width: width,
  };
  return (
    <div className="progress-bar-cotainer w-100">
      <span className="progress-bar" style={progressStyle}></span>
    </div>
  );
};

export default ProgressBar;
