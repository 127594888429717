import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Chart from "./Chart";
import "./style.css";
import Loader from "../../../Loader/Loader";
import { downloadContent } from "../../Create/SurveyUtils";
import BackButton from "../../../Dashboard/Components/Common/BackButton/BackButton";
import { downloadSurveyData } from "../../../../Services/SurveyAPIService";
import { decryptItemInLocal } from "../../../../utils/utils";
import SurveyInfo from "../SurveyInfo/SurveyInfo";

const ViewSurveyChart = (props) => {
  console.log(`----------- View Survey Chart -----------`, props);

  const loading = useSelector((state) => state.survey?.loading);
  const { state } = useLocation();
  const { surveyData, type, myPage, numbeOfRow, chartData } = state;
  const [questionsArray, setQuestionsArray] = useState([]);
  const [shortAnsAvailable, setShortAnsAvailable] = useState(false);
  const [channels] = useState(surveyData?.channel?.split(",") || []);

  const data = decryptItemInLocal("AuthToken");
  const Token = data?.data?.authTokenValue ? data?.data?.authTokenValue : null;
  const roleId = data?.data?.role;

  const fillTempArray = ()=>{
    let tmpArr = [];
    if (chartData?.length) {
      for (let storeItem of chartData) {
        if (!tmpArr?.length) {
          tmpArr.push({
            questionId: storeItem?.questionId,
            questionTitle: storeItem?.question,
            questionTypeId: storeItem?.questionTypeId,
            questionType: storeItem?.questionType,
            answersArr: [
              {
                option: storeItem?.answer,
                value: storeItem?.optionResponse,
              },
            ],
            totalResponse: storeItem?.totalResponse,
            totalTargetUserResponse: storeItem?.totalTargetUserResponse,
            totalGuestUserResponse: storeItem?.totalGuestUserResponse,
          });
          continue;
        }
        let obj = tmpArr.find(
          (item) => item?.questionId === storeItem?.questionId
        );
        if (obj !== undefined && Object.keys(tmpArr).length) {
          for (let arrItem of tmpArr) {
            if (arrItem?.questionId === storeItem?.questionId) {
              // arrItem.totalResponse =
              //   arrItem?.totalResponse + storeItem?.totalResponse;
              arrItem.answersArr.push({
                option: storeItem?.answer,
                value: storeItem?.optionResponse,
                timeStamp: storeItem?.createdDate || "",
              });
            }
          }
        } else {
          tmpArr.push({
            questionId: storeItem?.questionId,
            questionTitle: storeItem?.question,
            questionTypeId: storeItem?.questionTypeId,
            questionType: storeItem?.questionType,
            answersArr: [
              {
                option: storeItem?.answer,
                value: storeItem?.optionResponse,
                timeStamp: storeItem?.createdDate || "",
              },
            ],
            totalResponse: storeItem?.totalResponse,
            totalTargetUserResponse: storeItem?.totalTargetUserResponse,
            totalGuestUserResponse: storeItem?.totalGuestUserResponse,
          });
        }
        let index = tmpArr.findIndex((item) => item.questionTypeId === 4);
        if (index >= 0) setShortAnsAvailable(true);
      }
    }
    setQuestionsArray(tmpArr);
  }

  const downloadSurveyReport = async () => {
    try {
      let surveyId = surveyData?.id;
      const qParams = {
        id: surveyId,
      };
      const response = await downloadSurveyData(
        "get",
        "/Survey/DownloadExecutedSurvey",
        qParams
      );
      downloadContent(response.data);
    } catch (e) {
      console.log(`Error in downloadSurveyReport:`, e);
    }
  };

  useEffect(() => {
    fillTempArray();
  }, []);

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="container notification_wrapper viewSurvey">
        {/* ================================ Title ================================ */}
        <div className="title-area d-flex">
          <div className="left-icon">
            <BackButton
              pathname={"/survey/data"}
              paginationNumber={myPage}
              numbeOfRow={numbeOfRow}
            />
          </div>

          <div className="gbl-heading-txt title-view-survey">
            <div className="title-area">
              <h2>View Survey</h2>
            </div>
          </div>
          {questionsArray?.length > 0 && (
            <div className="">
              <button
                className="export-button fw-normal float-end bg-black ms-4 color-white"
                onClick={downloadSurveyReport}
              >
                Export Data
              </button>
            </div>
          )}
        </div>

        {/* ===================================== Survey INFO ===================================== */}
        <SurveyInfo surveyData={surveyData} />

        {/* ================================ Update Details ================================ */}
        {channels?.length > 0 &&
          (surveyData?.smsTriggered > 0 ||
            surveyData?.smsDelivered > 0 ||
            surveyData?.linkClicked > 0 ||
            surveyData?.whatsAppTriggered > 0 ||
            surveyData?.whatsAppDelivered > 0 ||
            surveyData?.whatsAppRead > 0 ||
            surveyData?.whatsAppLinkClicked > 0 ||
            surveyData?.emailTriggered > 0 ||
            surveyData?.emailDelivered > 0 ||
            surveyData?.emailLinkClicked > 0) && (
            <>
              <h2 className="leftheader">Update Details</h2>
              <div className="row">
                {/* Row 1 SMS */}
                {channels?.includes("SMS") &&
                  (surveyData?.smsTriggered > 0 ||
                    surveyData?.smsDelivered > 0 ||
                    surveyData?.linkClicked > 0) && (
                    <div>
                      <h4 className="mt-3">SMS</h4>
                      <div className="row px-3">
                        <div className="col-3 channel-title p-0">
                          SMS Triggered
                        </div>
                        <div className="col-3 channel-title p-0">
                          SMS Delivered
                        </div>
                        <div className="col-3 channel-title p-0">
                          Links Clicked
                        </div>
                      </div>
                      <div className="row px-3">
                        <div className="col-3 p-0">
                          {surveyData?.smsTriggered || 0}
                        </div>
                        <div className="col-3 p-0">
                          {surveyData?.smsDelivered || 0}
                        </div>
                        <div className="col-3 p-0">
                          {surveyData?.linkClicked || 0}
                        </div>
                      </div>
                    </div>
                  )}
                <br />

                {/* Row 2 Whatsapp*/}
                {channels?.includes("Whatsapp") &&
                  (surveyData?.whatsAppTriggered > 0 ||
                    surveyData?.whatsAppDelivered > 0 ||
                    surveyData?.whatsAppRead > 0 ||
                    surveyData?.whatsAppLinkClicked > 0) && (
                    <div>
                      <h4 className="mt-5">Whatsapp</h4>
                      <div className="row px-3">
                        <div className="col-3 channel-title p-0">
                          Whatsapp Triggered
                        </div>
                        <div className="col-3 channel-title p-0">
                          Whatsapp Delivered
                        </div>
                        <div className="col-3 channel-title p-0">
                          Whatsapp Read
                        </div>
                        <div className="col-3 channel-title p-0">
                          Links Clicked
                        </div>
                      </div>
                      <div className="row px-3">
                        <div className="col-3 p-0">
                          {surveyData?.whatsAppTriggered || 0}
                        </div>
                        <div className="col-3 p-0">
                          {surveyData?.whatsAppDelivered || 0}
                        </div>
                        <div className="col-3 p-0">
                          {surveyData?.whatsAppRead || 0}
                        </div>
                        <div className="col-3 p-0">
                          {surveyData?.whatsAppLinkClicked || 0}
                        </div>
                      </div>
                    </div>
                  )}
                <br />

                {/* Row 3 Email*/}
                {channels?.includes("Email") &&
                  (surveyData?.emailTriggered > 0 ||
                    surveyData?.emailDelivered > 0 ||
                    surveyData?.emailLinkClicked > 0) && (
                    <div>
                      <h4 className="mt-5">SMS</h4>
                      <div className="row px-3">
                        <div className="col-3 channel-title p-0">
                          Email Triggered
                        </div>
                        <div className="col-3 channel-title p-0">
                          Email Delivered
                        </div>
                        <div className="col-3 channel-title p-0">
                          Links Clicked
                        </div>
                      </div>
                      <div className="row px-3">
                        <div className="col-3 p-0">
                          {surveyData?.emailTriggered || 0}
                        </div>
                        <div className="col-3 p-0">
                          {surveyData?.emailDelivered || 0}
                        </div>
                        <div className="col-3 p-0">
                          {surveyData?.emailLinkClicked || 0}
                        </div>
                      </div>
                    </div>
                  )}
                <br />
              </div>
              <br />
              <br />
              <br />
              <br />
            </>
          )}

        {/* ================================ Survey Charts ================================ */}
        <h2 className="leftheader">Survey Results</h2>
        {questionsArray?.length === 0 && (
          <>
            <h4 className="text-center my-5">
              There has been no response yet. Please check back later.
            </h4>
            <br />
            <br />
          </>
        )}
        <br />
        {questionsArray?.length > 0 && (
          <div className="d-flex analysisTopRow">
            <div className="totalTarget">
              <label>Guest Response: </label>{" "}
              <span>{questionsArray[0]?.totalGuestUserResponse}</span>
            </div>
            <div className="totalTarget mx-5">
              <label>Target Response: </label>{" "}
              <span>{questionsArray[0]?.totalTargetUserResponse}</span>
            </div>
            <div className="totalResponse mx-5">
              <label>Total Response: </label>{" "}
              <span>{questionsArray[0]?.totalResponse}</span>
            </div>
          </div>
        )}

        {questionsArray?.length > 0 &&
          questionsArray?.map((item, index) => (
            <>
              <Chart
                key={index}
                questionNo={index + 1}
                graphData={item}
                surveyData={surveyData}
              />
            </>
          ))}
        {questionsArray?.length > 0 && shortAnsAvailable && (
          <div className="mb-5">
            <div className="best-result-img">
              <img src="/info-icon.svg" />
              Please export the data to see all of the short answers.
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ViewSurveyChart;
