import React, { useState, useEffect } from "react";
import DemographicSegmentation from "../DemographicSegmentation/DemographicSegmentation";
import InventoryChartMain from "../InventroyChart/InventoryChartMain";
import OverView from "../OverView/OverView";
import RevenueChartMain from "../RevenueChart/RevenueChartMain";
import SelectField from "../Common/SelectField";

const Sampling = "Sampling";
const Activity = "Activity";
const MainDashboard = () => {
  const [pageType, setPageType] = useState(Sampling);
  const [isActivity, setIsActivity] = useState(false);
  const handleActivityReality = (reality) => {
    setIsActivity(reality);
  };
  const options = [
    { name: "Sampling", id: 1 },
    { name: "Activity", id: 2 },
  ];

  const query = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (query.get("type") && isActivity == 1) {
      setPageType(query.get("type"));
    }
  }, [pageType]);

  const pageTypeHandler = (value) => {
    setPageType(value);
    let curLocation = window.location.pathname;
    window.history.replaceState(
      "",
      "",
      curLocation + "?type=" + value.toString()
    );
  };

  return (
    <>
      {isActivity === 1 && (
        <div className="col-sm-2 invisible_border dashboard_section autocomplete_dropdown">
          <SelectField
            lists={options}
            defaultValue={pageType || null}
            onChange={(e, value) => {
              pageTypeHandler(e.target.value);
            }}
            isDisabled={!isActivity}
          />
        </div>
      )}
      <>
        <OverView eventType={pageType} parentFunc={handleActivityReality} />
        <DemographicSegmentation
          ageRange={pageType === Sampling}
          incomeRange={pageType === Sampling}
          campaignType={pageType}
        />
        {pageType === Sampling && <InventoryChartMain />}
        {pageType === Sampling && <RevenueChartMain />}
      </>
    </>
  );
};

export default MainDashboard;
