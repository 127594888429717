import React from "react";
import "./Search.css";
function Search({ searchValue, setSearchValue, handleSearch, componentName }) {
  const componentNameArray = [
    "ProductSearch",
    "CampaignListSearch",
    "NotificationListSearch",
    "SurveyListSearch",
    "UserAccessSearch",
    "CustomerFeedbackSearch",
    "PollListSearch",
    "SurveyListSearch",
    "ReportSearch",
  ];

  const makeSearch = (e) => {
    let searchval = e.target.value;
    if (searchval !== "") {
      componentNameArray.forEach((value) => {
        if (value === componentName) {
          localStorage.setItem(value, searchval);
          setSearchValue(searchval || localStorage.getItem(value));
          handleSearch(
            searchval.trim().toLowerCase() ||
              localStorage.getItem(value).trim().toLowerCase()
          );
          //filterValueWithSearch();
        }
      });
    } else {
      componentNameArray.forEach((value) => {
        if (value === componentName) {
          localStorage.setItem(value, "");
          setSearchValue(searchval || localStorage.getItem(value));
          //localStorage.removeItem(value)
          handleSearch("");
          //filterValueWithSearch();
        }
      });

      //searchValuePresever();
    }
  };

  return (
    <div class="search-wrapper position-relative">
      <input
        type="text"
        name="focus"
        value={searchValue}
        className="form-control search-bar search_filter"
        placeholder="Search..."
        aria-label="Search"
        onChange={(e) => makeSearch(e)}
        autoComplete="off"
      />
      {searchValue && <button class="close-icon" onClick={makeSearch}></button>}
    </div>
  );
}

export default Search;
