import React from "react";
import BarChartOfficial from "../../../Common/Chart/BarChartOfficial";
import ColumnChartOfficial from "../../../Common/Chart/ColumnChartOfficial";
import PieChartOfficial from "../../../Common/Chart/PieChartOfficial";
import ViewMore from "./ViewMore";
import "./style.css";

const Chart = ({ questionNo, graphData, surveyData }) => {
  console.log(`graphData:`, graphData);

  return (
    <>
      <section className="chart-data-wrap">
        <p className="question-no">{`Question - ${questionNo}`}</p>
        <h3 className="que-title">{graphData?.questionTitle}</h3>
        <p className="responce-title">
          <span>Total responses :</span>
          <span>
            <b>&nbsp;{graphData?.totalResponse}</b>
          </span>
        </p>
        <div className="chart-wrap">
          {graphData?.questionTypeId === 1 && (
            <PieChartOfficial chartData={graphData} />
          )}
          {graphData?.questionTypeId === 2 && (
            <BarChartOfficial chartData={graphData} surveyData={surveyData} />
          )}
          {graphData?.questionTypeId === 3 && (
            <ColumnChartOfficial
              chartData={graphData}
              surveyData={surveyData}
            />
          )}
          {graphData?.questionTypeId === 4 && (
            <>
              <div className="short-answer-type">
                {graphData?.answersArr?.length > 5 &&
                  graphData?.answersArr?.slice(0, 5).map((element, index) => {
                    return (
                      <div key={index} className="answer-value">
                        <ViewMore sNo={index} content={element?.option} />
                      </div>
                    );
                  })}
                {graphData?.answersArr?.length <= 5 &&
                  graphData?.answersArr?.map((element, index) => {
                    return (
                      <div key={index} className="answer-value">
                        <ViewMore sNo={index} content={element?.option} />
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Chart;
